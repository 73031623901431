import React from 'react'

import Layout from '../../components/Layout'
import ExploreBarChart from '../../components/ExploreBarChart'

export default function BarChartPage() {
  return (
    <Layout>
      <ExploreBarChart />
    </Layout>
  )
}
