import React, { useMemo, useState, useCallback } from 'react'
import { Group } from '@visx/group'
import { GridRows } from '@visx/grid'
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale'
import { BarGroup, BarStack } from '@visx/shape'
import { schemeTableau10 as schemeSet } from 'd3-scale-chromatic'
import { max } from 'd3-array'
import { theme } from 'twin.macro'

import { valueFormatter, getPrecision } from '../util/charts'
import { getSubtitle, getRoundedString } from '../util'
import useSvgTooltip from '../connectors/useSvgTooltip'
import TooltipContent from './TooltipContent'
import Switch from '../components/Switch'
import Axes from './Axes'
import ChartTitle from './ChartTitle'
import Legend from './Legend'
import useData from '../data/useData'

const defaultMargin = { top: 70, left: 90, right: 40, bottom: 90 }

// Look for the categories to hide in all ancestors
function getHideValue(node, filteredList) {
  if (node === null) {
    return false
  }
  if (filteredList.indexOf(node.data.name) !== -1) {
    return true
  }
  return getHideValue(node.parent, filteredList)
}

function getMaxValue(nodes) {
  const values = nodes.map((node) => node.value)
  return max(values)
}

/**
 * Get all unique properties from objects in data array
 * @param {Object[]} data
 * @returns {string[]}
 */
function getKeys(data) {
  const keys = data.reduce((acc, curr) => {
    const allKeys = acc.concat(Object.keys(curr).filter((d) => d !== 'parent'))
    return [...new Set(allKeys)]
  }, [])
  return keys
}

function getDataFromNode(node) {
  const children = node.children.map((child) => ({
    [child.data.name]: child.value,
  }))

  return {
    parent: node.data.name,
    ...children.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  }
}

function getSortedKeys(level) {
  return [...new Set(level.map((d) => d.data.name))].sort()
}

function getOrgColorScale(orgCodes, orgColors, level) {
  const domain = getSortedKeys(level)
  const range = domain.map((d) => {
    const key = Object.keys(orgCodes).find((k) => orgCodes[k] === d)
    return orgColors[key] ?? theme`colors.blue.DEFAULT`
  })

  return {
    domain,
    range,
  }
}

/*
 * the data comes in getRoot, not root, because storybook doesn't tolerate
 * props that contain cycles.
 */
export default function BarChart({
  getRoot,
  parentWidth: width,
  parentHeight: height,
  margin = defaultMargin,
  filteredList,
}) {
  if (typeof getRoot !== 'function') {
    throw new Error('Need to supply a getRoot function')
  }
  const { orgCodes, orgColors } = useData()
  const root = useMemo(getRoot, [getRoot])

  const [stacked, setStacked] = useState(false)

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = scaleBand({
    domain: [root.data.name],
    range: [0, xMax],
    padding: 0.2,
  })
  const yScale = scaleLinear({
    domain: [0, root.value],
    range: [yMax, 0],
  }).nice()

  const colorScale = scaleOrdinal({ range: schemeSet })

  // Update scale based on children
  const level1 = root.descendants().filter((node) => node.depth === 1)
  const level2 = root.descendants().filter((node) => node.depth === 2)

  let nodes = level1.length ? level1 : root.descendants()

  const unit = root.data.unit
  let data = root.data.name
  let keys
  let level2Scale

  if (level1.length) {
    data = level1.map((node) => node.data.name)

    // Update scales
    xScale.domain(data)
    yScale.domain([0, getMaxValue(level1)]).nice()
  }

  if (level2.length) {
    data = level1.map((node) => getDataFromNode(node))

    keys = getKeys(data)

    // Update scales
    level2Scale = scaleBand({
      domain: keys,
      padding: 0.1,
    }).rangeRound([0, xScale.bandwidth()])
    yScale.domain([0, getMaxValue(level2)]).nice()
    colorScale.domain(keys)
  }

  if (stacked) {
    if (level2.length) {
      yScale.domain([0, getMaxValue(level1)]).nice()
    } else if (level1.length) {
      data = [getDataFromNode(root)]

      // Update scales
      xScale.domain([root.data.name])
      yScale.domain([0, root.value]).nice()
      keys = getKeys(data)
      colorScale.domain(keys)
    }
  }

  if (!level2.length && level1.find((d) => d.data.color)) {
    const { domain, range } = getOrgColorScale(orgCodes, orgColors, level1)
    colorScale.domain(domain).range(range)
  }
  if (level2.find((d) => d.data.color)) {
    const { domain, range } = getOrgColorScale(orgCodes, orgColors, level2)
    colorScale.domain(domain).range(range)
  }

  const {
    containerRef,
    handlePointer,
    TooltipInPortal,
    hideTooltip,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    tooltipData,
    tooltipStyles,
  } = useSvgTooltip()

  return width < 10 || root == null ? null : (
    <div tw="relative overflow-hidden">
      <StackedToggle stacked={stacked} setStacked={setStacked} />
      <svg width={width} height={height} ref={containerRef}>
        <rect width={width} height={height} fill="none" />
        <Group top={margin.top} left={margin.left}>
          <GridRows
            scale={yScale}
            width={xMax}
            stroke={theme`colors.grey.4`}
            pointerEvents="none"
          />
          <ChartTitle name={root.data.name} unit={root.data.unit} />

          {/* No levels */}
          {!level1.length && (
            <NormalBarChart
              nodes={nodes}
              isRoot
              xScale={xScale}
              yScale={yScale}
              yMax={yMax}
              unit={unit}
              handlePointer={handlePointer}
              hideTooltip={hideTooltip}
              colorScale={colorScale}
            />
          )}

          {/* Level1 */}
          {!level2.length && level1.length && (
            <>
              {stacked ? (
                <CustomBarStack
                  data={data}
                  keys={keys}
                  x={(d) => d.parent}
                  height={yMax}
                  xScale={xScale}
                  yScale={yScale}
                  color={colorScale}
                  unit={unit}
                  handlePointer={handlePointer}
                  hideTooltip={hideTooltip}
                />
              ) : (
                <NormalBarChart
                  nodes={nodes}
                  xScale={xScale}
                  yScale={yScale}
                  yMax={yMax}
                  filteredList={filteredList}
                  unit={unit}
                  handlePointer={handlePointer}
                  hideTooltip={hideTooltip}
                  colorScale={colorScale}
                />
              )}
            </>
          )}

          {/* Level2 */}
          {level2.length && (
            <>
              {stacked ? (
                <CustomBarStack
                  data={data}
                  keys={keys}
                  x={(d) => d.parent}
                  height={yMax}
                  xScale={xScale}
                  yScale={yScale}
                  color={colorScale}
                  unit={unit}
                  handlePointer={handlePointer}
                  hideTooltip={hideTooltip}
                />
              ) : (
                <CustomBarGroup
                  data={data}
                  keys={keys}
                  height={yMax}
                  x0={(d) => d.parent}
                  x0Scale={xScale}
                  x1Scale={level2Scale}
                  yScale={yScale}
                  color={colorScale}
                  unit={unit}
                  handlePointer={handlePointer}
                  hideTooltip={hideTooltip}
                />
              )}
            </>
          )}

          <Axes
            xScale={xScale}
            yScale={yScale}
            top={yMax}
            hasLabels={level1.length}
            leftTickFormat={valueFormatter}
          />
        </Group>

        {tooltipOpen && (
          <>
            <TooltipInPortal
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              <TooltipContent
                indicator={root.data.name}
                label={tooltipData.label}
                subLabel={getSubtitle(tooltipData.value, tooltipData.unit)}
              />
              {tooltipData.sum && (
                <div tw="mt-2 pt-2 flex justify-between border-t border-grey-5">
                  <span>Sum</span>
                  <span tw="pl-2 text-blue font-bold">
                    {tooltipData.sum}{' '}
                    {tooltipData.unit && (
                      <span tw="text-xs font-normal">{tooltipData.unit}</span>
                    )}
                  </span>
                </div>
              )}
            </TooltipInPortal>
          </>
        )}
      </svg>

      {/* Only show if level1 and stacked or level2 */}
      {((stacked && !!level1.length) || !!level2.length) && (
        <div tw="absolute top-10 right-10">
          <Legend scale={colorScale} isHorizontal={false} />
        </div>
      )}
    </div>
  )
}

function StackedToggle({ stacked, setStacked }) {
  return (
    <div tw="absolute mt-4 ml-6 flex items-center text-sm text-grey-1">
      <Switch
        selected={stacked}
        handleChange={() => setStacked((prev) => !prev)}
      />
      <span tw="ml-2">Stacked</span>
    </div>
  )
}

function CustomBar({ rx = 1, ...rest }) {
  return <rect rx={1} {...rest} />
}

function NormalBarChart({
  nodes,
  isRoot = false,
  xScale,
  yScale,
  yMax,
  filteredList,
  unit,
  handlePointer,
  hideTooltip,
  colorScale,
}) {
  const handleTooltip = useCallback(
    (e, node) => {
      const precision = getPrecision(node.value)

      handlePointer(e, {
        label: node.data.name,
        value: getRoundedString(node.value, precision),
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <>
      {nodes.map((node, i) => {
        const hide = filteredList ? getHideValue(node, filteredList) : false

        // Keep the root node's color constant, otherwise it's distracting if we change indicators.
        const bgColor = colorScale(isRoot ? 'indicator' : node.data.name)

        const barWidth = xScale.bandwidth()
        const barHeight = node.value === 0 ? 0 : yMax - yScale(node.value)
        const barX = xScale(node.data.name)
        const barY = yScale(node.value)

        return hide ? null : (
          <CustomBar
            key={`bar-${i}`}
            x={barX}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={bgColor}
            onPointerEnter={(e) => handleTooltip(e, node)}
            onPointerLeave={hideTooltip}
          />
        )
      })}
    </>
  )
}

function CustomBarGroup({
  data,
  keys,
  height,
  x0,
  x0Scale,
  x1Scale,
  yScale,
  color,
  unit,
  handlePointer,
  hideTooltip,
}) {
  const handleTooltip = useCallback(
    (e, bar) => {
      const precision = getPrecision(bar.value)

      handlePointer(e, {
        label: bar.key,
        value: getRoundedString(bar.value, precision),
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <BarGroup
      data={data}
      keys={keys}
      height={height}
      x0={x0}
      x0Scale={x0Scale}
      x1Scale={x1Scale}
      yScale={yScale}
      color={color}
    >
      {(barGroups) =>
        barGroups.map((barGroup) => (
          <Group
            key={`bar-group-${barGroup.index}-${barGroup.x0}`}
            left={barGroup.x0}
          >
            {barGroup.bars.map(
              (bar) =>
                bar.value && (
                  <CustomBar
                    key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill={bar.color}
                    onPointerEnter={(e) => handleTooltip(e, bar)}
                    onPointerLeave={hideTooltip}
                  />
                )
            )}
          </Group>
        ))
      }
    </BarGroup>
  )
}

function CustomBarStack({
  data,
  keys,
  height,
  x,
  xScale,
  yScale,
  color,
  unit,
  handlePointer,
  hideTooltip,
}) {
  const handleTooltip = useCallback(
    (e, { bar, key }) => {
      const precision = getPrecision(bar.data[key])

      const sum = Object.entries(bar.data)
        .filter(([key]) => key !== 'parent')
        .reduce((acc, [, value]) => acc + value, 0)

      handlePointer(e, {
        label: key,
        value: getRoundedString(bar.data[key], precision),
        sum: getRoundedString(sum, precision),
        unit,
      })
    },
    [handlePointer, unit]
  )

  return (
    <BarStack
      data={data}
      keys={keys}
      x={x}
      height={height}
      xScale={xScale}
      yScale={yScale}
      color={color}
    >
      {(barStacks) =>
        barStacks.map((barStack) =>
          barStack.bars.map(
            (bar) =>
              bar.y && (
                <CustomBar
                  key={`bar-stack-level1-${barStack.index}-${bar.index}`}
                  x={bar.x}
                  y={bar.y}
                  height={bar.height}
                  width={bar.width}
                  fill={bar.color}
                  onPointerEnter={(e) => handleTooltip(e, bar)}
                  onPointerLeave={hideTooltip}
                />
              )
          )
        )
      }
    </BarStack>
  )
}
