import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import { useFilter } from '../connectors/filterContext'
import ChartConfigSection from './ChartConfigSection'
import BarChart from './BarChart'
import useGetRoot from '../connectors/aggregateDataContext/useGetRoot'
import { HaulOptions, LocationOptions, TimeOptions } from './CustomOptions'

const filterDefinitions = [
  // TODO demographics: age, gender, employee type, length of employment
  // TODO travel: direct vs layover, airline, frequent flyers
  { name: 'Haul', key: 'haul', cat: 'travel', options: <HaulOptions /> },
  { name: 'Time', key: 'time', cat: 'travel', options: <TimeOptions /> },
  { name: 'Booking Class', key: 'cls', cat: 'travel' },
  {
    name: 'Destination',
    key: 'location',
    cat: 'travel',
    options: <LocationOptions />,
  },
  { name: 'Reason', key: 'flight_reason', cat: 'travel' },
  { name: 'Duration', key: 'duration', cat: 'travel' },
  { name: 'Age', key: 'age', cat: 'demographics' },
  { name: 'Gender', key: 'gender', cat: 'demographics' },
  { name: 'Role', key: 'role', cat: 'demographics' },
  { name: 'Manager', key: 'manager', cat: 'demographics' },
  { name: 'Org Code', key: 'org_code', cat: 'demographics' },
  // { name: 'Start Day', key: 'startDay', cat: 'travel' },
]

export default function ExploreBarChart() {
  const { getRoot } = useGetRoot(filterDefinitions)
  const [ref, { width, height }] = useMeasure()
  const { filteredList } = useFilter()

  return (
    <div tw="flex-1 flex flex-col lg:items-center justify-center lg:flex-row">
      <div
        css={[
          `height: calc(100vh - ${theme`spacing.20`});`,
          tw`w-full flex flex-col mx-auto lg:order-last overflow-x-auto`,
        ]}
        ref={ref}
      >
        {width && (
          <BarChart
            getRoot={getRoot}
            parentWidth={width}
            parentHeight={height}
            filteredList={filteredList}
          />
        )}
      </div>
      <ChartConfigSection
        filterDefinitions={filterDefinitions}
        disableFilters
        shouldHidePerFte
        tw="mt-4 lg:mt-0"
      />
    </div>
  )
}
